import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alerts"
export default class extends Controller {
  static targets = ["dismiss"]

  connect() {
    if (this.hasDismissTarget) {
      $(this.dismissTarget).click(() => {
        $(this.element).fadeOut()
      })
    }
  }
}
